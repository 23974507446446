const messages = {
  id: {
    translations: {
      signup: {
        title: "Daftar",
        toasts: {
          success: "Pengguna berhasil dibuat! Silakan masuk!",
          fail: "Error saat membuat pengguna. Periksa data yang diinput.",
        },
        form: {
          name: "Nama",
          email: "Email",
          password: "Kata Sandi",
        },
        buttons: {
          submit: "Daftar",
          login: "Sudah punya akun? Masuk!",
        },
      },
      login: {
        title: "Masuk",
        form: {
          email: "Email",
          password: "Kata Sandi",
        },
        buttons: {
          submit: "Masuk",
          register: "Belum punya akun? Daftar!",
        },
      },
      auth: {
        toasts: {
          success: "Berhasil masuk!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tiket hari ini: ",
          },
        },
        messages: {
          inAttendance: {
            title: "Dilayani"
          },
          waiting: {
            title: "Antri"
          },
          closed: {
            title: "Selesai"
          }
        }
      },
      connections: {
        title: "Koneksi",
        toasts: {
          deleted: "Koneksi WhatsApp berhasil dihapus!",
        },
        confirmationModal: {
          deleteTitle: "Hapus",
          deleteMessage: "Apakah Anda yakin? Tindakan ini tidak dapat dikembalikan.",
          disconnectTitle: "Putuskan",
          disconnectMessage: "Apakah Anda yakin? Anda perlu membaca QR Code lagi.",
        },
        buttons: {
          add: "Tambah WhatsApp",
          disconnect: "Putuskan",
          tryAgain: "Coba Lagi",
          qrcode: "QR CODE",
          newQr: "QR CODE Baru",
          connecting: "Menghubungkan",
        },
        toolTips: {
          disconnected: {
            title: "Gagal memulai sesi WhatsApp",
            content:
              "Pastikan ponsel Anda terhubung ke internet dan coba lagi, atau minta QR Code baru",
          },
          qrcode: {
            title: "Menunggu pembacaan QR Code",
            content:
              "Klik tombol 'QR CODE' dan baca QR Code dengan ponsel Anda untuk memulai sesi",
          },
          connected: {
            title: "Koneksi berhasil",
          },
          timeout: {
            title: "Koneksi dengan ponsel terputus",
            content:
              "Pastikan ponsel Anda terhubung ke internet dan WhatsApp terbuka, atau klik tombol 'Putuskan' untuk mendapatkan QR Code baru",
          },
        },
        table: {
          name: "Nama",
          status: "Status",
          lastUpdate: "Pembaruan Terakhir",
          default: "Default",
          actions: "Aksi",
          session: "Sesi",
        },
      },
      whatsappModal: {
        title: {
          add: "Tambah WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Nama",
          default: "Default",
        },
        buttons: {
          okAdd: "Tambah",
          okEdit: "Simpan",
          cancel: "Batal",
        },
        success: "WhatsApp berhasil disimpan.",
      },
      qrCode: {
        message: "Baca QR Code untuk memulai sesi",
      },
      contacts: {
        title: "Kontak",
        toasts: {
          deleted: "Kontak berhasil dihapus!",
        },
        searchPlaceholder: "Cari ...",
        confirmationModal: {
          deleteTitle: "Hapus",
          importTitlte: "Impor kontak",
          deleteMessage:
            "Apakah Anda yakin ingin menghapus kontak ini? Semua tiket terkait akan hilang.",
          importMessage: "Apakah Anda ingin mengimpor semua kontak dari ponsel?",
        },
        buttons: {
          import: "Impor Kontak",
          add: "Tambah Kontak",
        },
        table: {
          name: "Nama",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Aksi",
        },
      },
      contactModal: {
        title: {
          add: "Tambah kontak",
          edit: "Edit kontak",
        },
        form: {
          mainInfo: "Detail Kontak",
          extraInfo: "Informasi Tambahan",
          name: "Nama",
          number: "Nomor Whatsapp",
          email: "Email",
          extraName: "Nama Field",
          extraValue: "Nilai",
        },
        buttons: {
          addExtraInfo: "Tambah informasi",
          okAdd: "Tambah",
          okEdit: "Simpan",
          cancel: "Batal",
        },
        success: "Kontak berhasil disimpan.",
      },
      quickAnswersModal: {
        title: {
          add: "Tambah Balasan Cepat",
          edit: "Edit Balasan Cepat",
        },
        form: {
          shortcut: "Pintasan",
          message: "Balasan Cepat",
        },
        buttons: {
          okAdd: "Tambah",
          okEdit: "Simpan",
          cancel: "Batal",
        },
        success: "Balasan Cepat berhasil disimpan.",
      },
      queueModal: {
        title: {
          add: "Tambah antrian",
          edit: "Edit antrian",
        },
        form: {
          name: "Nama",
          color: "Warna",
          greetingMessage: "Pesan Sambutan",
        },
        buttons: {
          okAdd: "Tambah",
          okEdit: "Simpan",
          cancel: "Batal",
        },
      },
      userModal: {
        title: {
          add: "Tambah pengguna",
          edit: "Edit pengguna",
        },
        form: {
          name: "Nama",
          email: "Email",
          password: "Kata Sandi",
          profile: "Profil",
          whatsapp: "Koneksi Default",
        },
        buttons: {
          okAdd: "Tambah",
          okEdit: "Simpan",
          cancel: "Batal",
        },
        success: "Pengguna berhasil disimpan.",
      },
      chat: {
        noTicketMessage: "Pilih tiket untuk memulai chatting.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Baru",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Antrian",
      },
      tickets: {
        toasts: {
          deleted: "Tiket yang Anda gunakan telah dihapus.",
        },
        notification: {
          message: "Pesan dari",
        },
        tabs: {
          open: { title: "Kotak Masuk" },
          closed: { title: "Selesai" },
          search: { title: "Cari" },
        },
        search: {
          placeholder: "Cari tiket dan pesan.",
        },
        buttons: {
          showAll: "Semua",
        },
      },
      transferTicketModal: {
        title: "Transfer Tiket",
        fieldLabel: "Ketik untuk mencari pengguna",
        fieldQueueLabel: "Transfer ke antrian",
        fieldConnectionLabel: "Transfer ke koneksi",
        fieldQueuePlaceholder: "Silakan pilih antrian",
        fieldConnectionPlaceholder: "Silakan pilih koneksi",
        noOptions: "Tidak ada pengguna ditemukan dengan nama ini",
        buttons: {
          ok: "Transfer",
          cancel: "Batal",
        },
      },
      ticketsList: {
        pendingHeader: "Antrian",
        assignedHeader: "Sedang dikerjakan",
        noTicketsTitle: "Tidak ada yang ada di sini!",
        noTicketsMessage: "Tidak ada tiket ditemukan dengan status atau kata kunci pencarian ini.",
        connectionTitle: "Koneksi yang sedang digunakan.",
        buttons: {
          accept: "Terima",
        },
      },
      newTicketModal: {
        title: "Buat Tiket",
        fieldLabel: "Ketik untuk mencari kontak",
        add: "Tambah",
        buttons: {
          ok: "Simpan",
          cancel: "Batal",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dasbor",
          connections: "Koneksi",
          tickets: "Tiket",
          contacts: "Kontak",
          quickAnswers: "Balasan Cepat",
          queues: "Antrian",
          administration: "Administrasi",
          users: "Pengguna",
          settings: "Pengaturan",
        },
        appBar: {
          user: {
            profile: "Profil",
            logout: "Keluar",
          },
        },
      },
      notifications: {
        noTickets: "Tidak ada notifikasi.",
      },
      queues: {
        title: "Antrian",
        table: {
          name: "Nama",
          color: "Warna",
          greeting: "Pesan Sambutan",
          actions: "Aksi",
        },
        buttons: {
          add: "Tambah antrian",
        },
        confirmationModal: {
          deleteTitle: "Hapus",
          deleteMessage:
            "Apakah Anda yakin? Tindakan ini tidak dapat dikembalikan! Tiket dalam antrian ini masih ada, tetapi tidak akan memiliki antrian yang ditugaskan.",
        },
      },
      queueSelect: {
        inputLabel: "Antrian",
      },
      quickAnswers: {
        title: "Balasan Cepat",
        table: {
          shortcut: "Pintasan",
          message: "Balasan Cepat",
          actions: "Aksi",
        },
        buttons: {
          add: "Tambah Balasan Cepat",
        },
        toasts: {
          deleted: "Balasan Cepat berhasil dihapus.",
        },
        searchPlaceholder: "Cari...",
        confirmationModal: {
          deleteTitle: "Apakah Anda yakin ingin menghapus Balasan Cepat ini: ",
          deleteMessage: "Tindakan ini tidak dapat dibatalkan.",
        },
      },
      users: {
        title: "Pengguna",
        table: {
          name: "Nama",
          email: "Email",
          profile: "Profil",
          whatsapp: "Koneksi Default",
          actions: "Aksi",
        },
        buttons: {
          add: "Tambah pengguna",
        },
        toasts: {
          deleted: "Pengguna berhasil dihapus.",
        },
        confirmationModal: {
          deleteTitle: "Hapus",
          deleteMessage:
            "Semua data pengguna akan hilang. Tiket terbuka pengguna akan dipindahkan ke antrian.",
        },
      },
      settings: {
        success: "Pengaturan berhasil disimpan.",
        title: "Pengaturan",
        settings: {
          userCreation: {
            name: "Pembuatan Pengguna",
            options: {
              enabled: "Aktif",
              disabled: "Nonaktif",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Ditugaskan kepada:",
          buttons: {
            return: "Kembali",
            resolve: "Selesaikan",
            reopen: "Buka Kembali",
            accept: "Terima",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Ketik pesan atau tekan ''/'' untuk menggunakan balasan cepat yang terdaftar",
        placeholderClosed: "Buka kembali atau terima tiket ini untuk mengirim pesan.",
        signMessage: "Tanda Tangan",
      },
      contactDrawer: {
        header: "Detail Kontak",
        buttons: {
          edit: "Edit kontak",
        },
        extraInfo: "Informasi Lainnya",
      },
      ticketOptionsMenu: {
        delete: "Hapus",
        transfer: "Transfer",
        confirmationModal: {
          title: "Hapus tiket #",
          titleFrom: "dari kontak ",
          message: "Perhatian! Semua pesan terkait tiket akan hilang.",
        },
        buttons: {
          delete: "Hapus",
          cancel: "Batal",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Batal",
        },
      },
      messageOptionsMenu: {
        delete: "Hapus",
        reply: "Balas",
        confirmationModal: {
          title: "Hapus pesan?",
          message: "Tindakan ini tidak dapat dikembalikan.",
        },
      },
      chatOptions: {
        welcome_message: "Selamat datang! Bagaimana kami dapat membantu Anda hari ini?",
        product_info: "Pelajari lebih lanjut tentang produk kami",
        support: "Dapatkan dukungan atau laporkan masalah",
        pricing: "Temukan informasi tentang harga",
        more_services: "Temukan layanan tambahan kami",
        talk_to_human: "Bicaralah dengan agen dukungan pelanggan",
        end_chat: "Akhiri percakapan ini",
        why_erp: "Mengapa memilih solusi ERP kami?",
        erp_modules: "Jelajahi modul ERP kami",
        erp_benefits: "Temukan manfaat dari solusi ERP kami",
        integration_capabilities: "Kemampuan integrasi dari ERP kami",
        installation_guide: "Panduan instalasi",
        contact_support: "Hubungi dukungan",
        feedback: "Tinggalkan umpan balik",
        subscription_plans: "Paket langganan",
        free_trial: "Mulai percobaan gratis",
        discounts: "Pelajari tentang diskon dan penawaran kami",
        refund: "Kebijakan pengembalian dana",
        training_workshops: "Pelatihan & Workshop",
        customization: "Opsi kustomisasi",
        partnership: "Peluang kemitraan",
        upcoming_features: "Fitur yang akan datang",
        back: "Kembali ke menu sebelumnya",
        main_menu: "Kembali ke menu utama"
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "Minimal harus ada satu koneksi WhatsApp default.",
        ERR_NO_DEF_WAPP_FOUND:
          "Tidak ada WhatsApp default ditemukan. Periksa halaman koneksi.",
        ERR_WAPP_NOT_INITIALIZED:
          "Sesi WhatsApp ini belum diinisialisasi. Periksa halaman koneksi.",
        ERR_WAPP_CHECK_CONTACT:
          "Tidak dapat memeriksa kontak WhatsApp. Periksa halaman koneksi.",
        ERR_WAPP_INVALID_CONTACT: "Ini bukan nomor WhatsApp yang valid.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Tidak dapat mengunduh media dari WhatsApp. Periksa halaman koneksi.",
        ERR_INVALID_CREDENTIALS: "Kesalahan autentikasi. Silakan coba lagi.",
        ERR_SENDING_WAPP_MSG:
          "Kesalahan saat mengirim pesan WhatsApp. Periksa halaman koneksi.",
        ERR_DELETE_WAPP_MSG: "Tidak dapat menghapus pesan dari WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "Sudah ada tiket terbuka untuk kontak ini.",
        ERR_SESSION_EXPIRED: "Sesi berakhir. Silakan Login.",
        ERR_USER_CREATION_DISABLED:
          "Pembuatan pengguna dinonaktifkan oleh administrator.",
        ERR_NO_PERMISSION: "Anda tidak memiliki izin untuk mengakses sumber daya ini.",
        ERR_DUPLICATED_CONTACT: "Kontak dengan nomor ini sudah ada.",
        ERR_NO_SETTING_FOUND: "Tidak ada pengaturan ditemukan dengan ID ini.",
        ERR_NO_CONTACT_FOUND: "Tidak ada kontak ditemukan dengan ID ini.",
        ERR_NO_TICKET_FOUND: "Tidak ada tiket ditemukan dengan ID ini.",
        ERR_NO_USER_FOUND: "Tidak ada pengguna ditemukan dengan ID ini.",
        ERR_NO_WAPP_FOUND: "Tidak ada WhatsApp ditemukan dengan ID ini.",
        ERR_CREATING_MESSAGE: "Kesalahan saat membuat pesan di database.",
        ERR_CREATING_TICKET: "Kesalahan saat membuat tiket di database.",
        ERR_FETCH_WAPP_MSG:
          "Kesalahan saat mengambil pesan di WhatsApp, mungkin terlalu lama.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Warna ini sudah digunakan, pilih yang lain.",
        ERR_WAPP_GREETING_REQUIRED:
          "Pesan sambutan diperlukan jika ada lebih dari satu antrian.",
      },
    },
  },
};

export { messages };

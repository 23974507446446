import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { messages } from "./languages";

/*
i18n.use(LanguageDetector).init({
	debug: false,
	defaultNS: ["translations"],
	fallbackLng: "en",
	ns: ["translations"],
	resources: messages,
});
*/
i18n.init({
    debug: false,
    defaultNS: ["translations"],
    lng: "id", // Set default language to Bahasa Indonesia
    fallbackLng: "en", // Set fallback language to English
    ns: ["translations"],
    resources: messages,
});

export { i18n };
